
import { Component, Vue, Watch } from 'vue-property-decorator';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { PublishingModule } from '@/store/modules/publishing.module';
import GButton from '@/components/gsk-components/GskButton.vue';
import { FormField, SelectOption } from '@/components/form/form.types';
import GeneratedForm from '@/components/form/GeneratedForm.vue';
import SelectedApi from '@/components/Publish/SelectedApi.vue';
import SelectedMobileComponent from '@/components/Publish/SelectedMobileComponent.vue';
import GithubRepoLink from '@/components/Publish/GithubRepoLink.vue';
import SelectedApiVersion from '@/components/Publish/SelectedApiVersion.vue';
import { ListingTypes } from '@/constants';
import { cloneDeep } from 'lodash';

@Component({
  components: {
    GSelect,
    GButton,
    GeneratedForm,
    SelectedApi,
    SelectedApiVersion,
    SelectedMobileComponent,
    GithubRepoLink,
  },
})
export default class ProductDetails extends Vue {
  public label = 'Select Product*';
  public ready = false;
  public valid = false;

  get isEdit() {
    return PublishingModule.mode.edit;
  }

  get isVersion() {
    return PublishingModule.mode.version;
  }

  get content() {
    const d = PublishingModule.listingTypeTemplate.publishing.content.details;
    if (this.isEdit) {
      return d.edit;
    }
    return d;
  }

  get ghValid(): boolean {
    if (this.valid) {
      return PublishingModule.linkedGithubRepoValid;
    }
    return false;
  }

  get options(): SelectOption[] {
    return PublishingModule.listingTypes
      .filter(o => {
        return o.listingTypeId !== 0 && o.listingTypeId !== 4 && o.listingTypeId !== 6;
      })
      .map(o => ({
        value: o.listingTypeId.toString(),
        label: o.name,
        extra: o.template,
      }));
  }

  setListingTypeByRoute() {
    const { r, rv } = this.$route.query;
    if (r && rv) {
      this.listingType = ListingTypes.API.toString();
    }
  }

  created() {
    if(this.listingType === undefined) {
      this.setListingTypeByRoute();
    }
  }

  @Watch('options')
  onOptionsLoad() {
    this.setListingTypeByRoute();
  }

  setListingType(listingTypeId: any) {
    PublishingModule.setListingTypeId(listingTypeId);
    const opt = this.options.find(o => o.value === listingTypeId);
    if (opt) {
      PublishingModule.createExtendedPropertiesFromTemplate(opt.extra);
    }
  }

  onProductTypeChange(newListingTypeId: any) {
    const currentListingUsers = cloneDeep(PublishingModule.draftListing.listingUsers);
    const currentListingTeams = cloneDeep(PublishingModule.draftListing.teams);
    PublishingModule.resetPublishingState();
    this.setListingType(newListingTypeId);
    PublishingModule.addUsersWithPermissions(currentListingUsers);
    PublishingModule.addTeamsWithPermissions(currentListingTeams);
  }

  get listingType(): string {
    const id = PublishingModule.draftListing.listingTypeId;
    if (id) {
      return id.toString();
    }
    return '';
  }

  set listingType(listingTypeId: string) {
    this.setListingType(listingTypeId);
  }

  get productDetails(): FormField[] {
    return PublishingModule.draftListing.extendedProperties.details;
  }

  set productDetails(form: FormField[]) {
    PublishingModule.setDetailsForm(form);
  }

  next() {
    PublishingModule.goToNextComponent();
  }

  get listingTypeId() {
    return PublishingModule.draftListing.listingTypeId;
  }

  get isCustomComponent() {
    return PublishingModule.listingTypeTemplate.publishing.details.isCustomComponent;
  }

  get productDetailsComponent() {
    // this is bad and i know it
    this.$log('version', PublishingModule.mode.version);
    return [
      null,
      PublishingModule.mode.version ? SelectedApiVersion : SelectedApi,
      null,
      SelectedMobileComponent,
      null,
      null,
    ][this.listingTypeId];
  }
}
