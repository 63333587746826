
import { Component, Vue } from 'vue-property-decorator';
import GButton from '../gsk-components/GskButton.vue';
import { PublishingModule } from '@/store/modules/publishing.module';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GskMultiSelect from '@/components/gsk-components/GskMultiSelect.vue';
import {
  FormField,
  isSelectOptions,
  MultiSelectField,
  SelectField,
  SelectOption,
  SelectValue,
} from '@/components/form/form.types';
import GeneratedForm from '@/components/form/GeneratedForm.vue';
import GithubRepoLink from '@/components/Publish/GithubRepoLink.vue';

type Fields = [MultiSelectField, SelectField, SelectField];

function isMultiSelect(f: FormField): f is MultiSelectField {
  return f.type === 'multi-select';
}

@Component({
  components: {
    GButton,
    GTextfield,
    GskMultiSelect,
    GeneratedForm,
    GithubRepoLink,
  },
})
export default class SelectedMobileComponent extends Vue {
  private valid = false;

  get content() {
    return PublishingModule.listingTypeTemplate.publishing.content.details.edit;
  }

  get isEdit() {
    return PublishingModule.mode.edit;
  }

  get fields(): Fields {
    return PublishingModule.draftListing.extendedProperties.details as Fields;
  }

  set fields(f: Fields) {
    const os = f[0];
    const framework = f[1];
    const lang = f[2];

    // set options
    framework.options = this.frameworkOptions(os.value);
    framework.hide = !os.value.length || !framework.options.length;
    this.$set(f, 1, framework);
    lang.options = this.languageOptions(framework.value);
    lang.hide = !framework.value.length || !lang.options.length;
    this.$set(f, 2, lang);

    for (const field of [framework, lang]) {
      let o;
      const v = field.value;
      if (isSelectOptions(field.options)) {
        o = field.options.map((o: SelectOption): string => o.value);
      } else {
        o = field.options;
      }

      const opts = new Set(o);
      field.value = opts.has(v) ? v : '';
    }

    PublishingModule.setDetailsForm(f);
  }

  frameworkOptions(val: SelectValue[]): string[] {
    const ios = ['Native iOS'];
    const android = ['Native Android'];
    const rest = ['React Native', 'Flutter', 'Ionic', 'Native Script', 'Xamarin'];
    const os = val;
    if (os.length === 1) {
      if (os[0] === 'iOS') {
        return [...ios, ...rest];
      } else {
        return [...android, ...rest];
      }
    }
    return rest;
  }

  languageOptions(val: SelectValue): string[] {
    let out: string[] = [];
    const framework = val;
    const inc = (v: string) => framework === v;
    if (inc('Native Android')) {
      out.push('Java', 'Kotlin');
    }
    if (inc('Native iOS')) {
      out.push('Objective-C', 'Swift');
    }
    if (inc('Xamarin')) {
      out.push('C#');
    }
    if (inc('Flutter')) {
      out.push('Dart');
    }
    return out;
  }

  next() {
    PublishingModule.goToNextComponent();
  }
}
