
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import * as types from './form.types';
import { isValid } from '@/components/form/utils';
import dayjs from 'dayjs';

const components = {
  'g-select': () => import('@/components/gsk-components/GskSelect.vue'),
  'g-multi-select': () => import('@/components/gsk-components/GskMultiSelect.vue'),
  'g-radio': () => import('@/components/gsk-components/GskRadioGroup.vue'),
  'g-checkbox': () => import('@/components/gsk-components/GskCheckboxGroup.vue'),
  'g-text-field': () => import('@/components/gsk-components/GskTextfield.vue'),
  'g-people-picker': () => import('@/components/gsk-components/GskPeoplePicker.vue'),
  'repo-picker': () => import('@/components/RepoPicker.vue'),
};

/**
 * @deprecated use validated form for new stuff
 */
@Component({
  components,
})
export default class GeneratedForm extends Vue {
  @Prop({ type: Array, required: true }) value!: types.FormField[];

  get filteredValue() {
    return this.value.filter(v => !v.hide);
  }

  get isDev() {
    return process.env.NODE_ENV === 'development';
  }

  @Watch('value', { immediate: true })
  valueWatcher() {
    this.$nextTick(() => {
      this.checkValidity(this.value);
    });
  }

  @Emit('input')
  onChange(field: types.FormField, value: types.FormField['value']) {
    const model = cloneDeep(this.value);
    const mf = model.find(modelField => modelField.key === field.key);
    if (mf) {
      mf.value = value;
    }
    return model;
  }

  onInvalid() {
    this.$log('on invalid event');
  }

  checkValidity(form: types.FormField[]): void {
    this.$log('check validity called');
    const v = isValid(form);
    this.$emit('valid', v);
  }

  helpText(field: types.FormField): string {
    return field.helpText || ' ';
  }

  textFieldType(field: types.FormField) {
    if (field.type === 'long-text' || field.type === 'text') {
      if (field.type === 'long-text') {
        return 'textarea';
      }
      if (field.attrs?.type) {
        return field.attrs?.type;
      }

      return 'text';
    }
  }
  maxDate(field :types.FormField) {
    return dayjs().format("YYYY-MM-DD");
  }
}
