
import { Component, Vue } from 'vue-property-decorator';
import uniqBy from 'lodash/uniqBy';
import GSelect from '../gsk-components/GskSelect.vue';
import GButton from '../gsk-components/GskButton.vue';
import { GetRegistrationsResponseData } from '@/types/listings.types';
import { SelectOption } from '../form/form.types';
import { PublishingModule } from '@/store/modules/publishing.module';
import { RegistrationVersions } from '@/types/publishing.types';

const MISSING = 'missing-api';

@Component({
  components: {
    GSelect,
    GButton,
  },
})
export default class SelectedApiVersion extends Vue {
  public labelOfVersion = 'Select Version';

  showHelp = false;
  showHelpVersion = false;

  next() {
    PublishingModule.goToNextComponent();
  }

  get apiName() {
    return PublishingModule.draftListing.listingName;
  }

  async getRegistrationVersions(id: number) {
    await PublishingModule.getRegistrationVersions(id);
    const versions: RegistrationVersions[] = PublishingModule.registrationVersions.versions || [];
    if (versions.length) {
      this.registrationVersion = versions[0].registrationVersionId.toString();
    }
  }

  public async created(): Promise<void> {
    const id = PublishingModule.draftListing.registrationId;
    this.getRegistrationVersions(id);
  }

  set registrationId(registrationId) {
    if (registrationId === MISSING) {
      this.showHelp = true;
    } else {
      this.showHelp = false;
      this.registrationVersion = '';
      const id = Number(registrationId);
      PublishingModule.updateRegistrationIdAndProductLabels(id);
      this.getRegistrationVersions(id);
    }
  }

  get registrationId(): string {
    if (this.showHelp) {
      return MISSING;
    }
    const { registrationId } = this.listing;
    const optionObj = this.registrationOptions.find(
      option => option.registrationId === registrationId,
    );
    return optionObj ? optionObj.registrationId.toString() : '';
  }

  set registrationVersion(id: string) {
    if (id === MISSING) {
      this.showHelpVersion = true;
    } else {
      this.showHelpVersion = false;
      PublishingModule.setRegistrationVersionId(Number(id));
    }
  }

  get registrationVersion(): string {
    if (this.showHelpVersion) {
      return MISSING;
    }
    const { registrationVersionId } = PublishingModule.draftListing;
    return registrationVersionId === 0 ? '' : registrationVersionId.toString();
  }

  get registrationOptions(): GetRegistrationsResponseData[] {
    return PublishingModule.registrationOptions;
  }

  get registrationHasAvailableVersions(): boolean {
    return PublishingModule.registrationHasVersions && this.registrationId !== MISSING;
  }

  get registrationVersionId(): number {
    return PublishingModule.draftListing.registrationVersionId;
  }

  get listing() {
    return PublishingModule.draftListing;
  }

  get isAddingNewVersion(): boolean {
    return PublishingModule.statusList[0].key === '3';
  }

  get disabled(): boolean {
    return this.showHelp || this.showHelpVersion || this.registrationVersionId === 0;
  }

  get availableRegistrations(): SelectOption[] {
    const registrations: GetRegistrationsResponseData[] = PublishingModule.registrationOptions;
    return uniqBy(
      registrations.map(reg => {
        let obj: SelectOption = {
          value: reg.registrationId.toString(),
          label: reg.resourceName,
          disabled: false,
        };
        return obj;
      }),
      'value',
    ).concat([
      {
        value: MISSING,
        label: "I don't see what I'm looking for",
        disabled: false,
      },
    ]);
  }

  get selectedRegistrationVersions(): SelectOption[] {
    const versions: RegistrationVersions[] = PublishingModule.registrationVersions.versions || [];
    return versions
      .map(version => {
        let obj: SelectOption = {
          value: version.registrationVersionId.toString(),
          label: 'v' + version.versionId.toString(),
          disabled: false,
        };
        return obj;
      })
      .concat([
        {
          value: MISSING,
          label: "I don't see what I'm looking for",
          disabled: false,
        },
      ]);
  }
}
