
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import cloneDeep from 'lodash/cloneDeep';
import { AxiosError, AxiosPromise } from 'axios';
import pick from 'lodash/pick';
import VerticalTab from '@/components/Publish/VerticalTab.vue';
import { UITab } from '@/types';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import ProductDetails from '@/components/Publish/ProductDetails.vue';
import ProductCard from '@/components/Publish/ProductCard.vue';
import ListingPermissions from '@/components/Publish/Permissions.vue';
import AddDocumentation from '@/components/Publish/AddDocumentation.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import UserList from '@/components/UserList.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { ListingTypes, RoleNames, RouteNames, Roles } from '@/constants';
import * as API from '@/api/publishing.api';
import {
  DocumentationSection,
  DraftListingModule,
  DraftListingModulePayload,
  ListingUser,
  SavedDetails,
} from '@/types/listings.types';
import { PublishingModule } from '@/store/modules/publishing.module';
import { UserModule } from '@/store/modules/user.module';
import { openSnackbar } from '@/utils/components';
import { FormField, TextField } from '@/components/form/form.types';
import HelpLink from '@/components/HelpLink.vue';
import invariant from 'ts-invariant';
import { cleanOutSpecialCharAndTags } from '@/utils/routing';

type PublishForm = [TextField<'PublishChangeReason'>];

@Component({
  components: {
    VerticalTab,
    GSelect,
    SelectProd: ProductDetails,
    ProductCard,
    AddDocumentation,
    DefaultLayout,
    ValidatedFormDialog,
    MaxWidth,
    UserList,
    GButton,
    ListingPermissions,
    HelpLink,
  },
})
export default class PublishNew extends Vue {
  @Prop(String) readonly listingId!: string | undefined;
  @Prop(String) readonly listingVersionId!: string | undefined;
  @Prop(String) readonly publishMessage!: string;

  public product = 'Listing';
  private showPermissions = false;
  private loading = false;

  publishOpen = false;
  file = [];
  open = false;

  publishForm: PublishForm = [
    {
      key: 'PublishChangeReason',
      label: 'Briefly explain the changes you have made.',
      type: 'long-text',
      value: '',
      required: true,
      attrs: {
        placeHolder: 'What was changed ?',
        maxLength: '1000',
      },
    },
  ];

  addDocSection(section: DocumentationSection): void {
    PublishingModule.addDocumentationSection(section);
  }
  setDocSection(payload: { documentationSection: DocumentationSection; index: number }): void {
    PublishingModule.setDocumentationSection(payload);
  }
  removeDocSection(index: number): void {
    PublishingModule.removeDocumentationSection(index);
  }

  moveDocSection(payload: { index: number; direction: 'up' | 'down' }): void {
    PublishingModule.moveDocumentationSection(payload);
  }

  public get owners(): ListingUser[] {
    const { user: u } = UserModule;
    if (u.userId === -1) {
      return [];
    }
    const owner: ListingUser = {
      firstName: u.firstName,
      lastName: u.lastName,
      fullName: u.fullName,
      email: u.email,
      userId: u.userId,
      mudId: u.mudId,
      roleId: Roles.Owner, // 1
      roleName: RoleNames.Owner, // Owner
    };

    let allMembers: ListingUser[] = [];
    if (this.currentDraftListing.listingUsers) {
      allMembers =
        this.currentDraftListing.listingUsers.length === 0
          ? [owner]
          : [...this.currentDraftListing.listingUsers.filter(ref=> ref.roleId === Roles.Owner)];
    }
    this.currentDraftListing.teams.map(team => {
      if (team.roleId === Roles.Owner) {
        team.teamMembers?.map(member => {
          if (!allMembers.find((r: ListingUser) => member.mudId == r.mudId)) {
            allMembers.push(member);
          }
        });
      }
    });
    return allMembers;
  }

  public get pm(): typeof PublishingModule {
    return PublishingModule;
  }

  transformFormFields(form: FormField[]): SavedDetails {
    return form.map(field => {
      return pick(field, ['value', 'key', 'type']);
    });
  }

  public get isApi() {
    return this.currentDraftListing.listingTypeId === ListingTypes.API;
  }

  async saveDraft() {
    let op: AxiosPromise<number | boolean | API.CreateDraftVersionableResponse> | undefined;
    const draft: DraftListingModulePayload = cloneDeep(this.currentDraftListing);
    if (draft.listingTypeId !== ListingTypes.API) {
      delete draft.registrationId;
      delete draft.registrationVersionId;
    }

    // cleanup legacy unsupported extended props
    const extProps: any = draft.extendedProperties;

    delete extProps.serviceName;
    extProps.documentationSections.forEach((ref:any) => {
      delete ref.sectionInfo.sectionIndex;
    });


    draft.extendedProperties = extProps;

    if (!PublishingModule.isCustomComponent) {
      (draft.extendedProperties.details as SavedDetails) = this.transformFormFields(
        draft.extendedProperties.details,
      );
    }
    this.loading = true;

    if (PublishingModule.mode.edit) {
      // eslint-disable-next-line no-console
      console.assert(this.listingId !== undefined, 'Listing ID should be defined in edit mode');
      if (this.listingId) {
        draft.listingId = Number(this.listingId);
        if (this.isApi) {
          // eslint-disable-next-line no-console
          console.assert(
            this.listingVersionId !== undefined,
            'Listing Version ID should be defined in edit mode',
          );
          // API updates always call "version" endpoints
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          op = API.updateDraftListingVersion(draft, this.listingVersionId!);
        } else {
          op = API.updateDraftListing(draft, this.listingId, this.publishMessage);
        }
      }
    } else {
      // initial creation
      PublishingModule.addUsersWithPermissions(this.owners);
      if (PublishingModule.mode.version) {
        // eslint-disable-next-line no-console
        console.assert(
          this.listingId !== undefined,
          'Listing ID should be defined in version mode',
        );
        if (this.listingId) {
          draft.listingId = Number(this.listingId);
          op = API.createDraftListingVersion(draft, this.listingId);
        }
      } else {
        op = API.createDraftListing(draft);
      }
    }
    if (op) {
      op.then(res => {
        if (typeof res.data === 'number') {
          //  then we've created a new draft and need to redirect
            this.$router.replace({
              name: RouteNames.PublishListing,
              params: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                listingId: this.listingId!,
                listingVersionId: res.data.toString(),
              },
              query: {
                mode: 'edit',
              },
            });
            PublishingModule.setMode({
              mode: {
                version: false,
                edit: true,
              },
              initial: false,
            });
        } else if (typeof res.data === 'object') {
          // res.data is object like
          // { draftListingId: "17",
          //   draftListingVersionId: "14" }

          type Params = { listingId: string; listingVersionId?: string };

          const params: Params = {
            listingId: res.data.draftListingId,
            listingVersionId: res.data.draftListingVersionId,
          };

          if (!res.data.draftListingVersionId) {
            delete params.listingVersionId;
          }

          this.$router.replace({
            name: RouteNames.PublishListing,
            params,
            query: {
              mode: 'edit',
            },
          });
          PublishingModule.setMode({
            mode: {
              version: false,
              edit: true,
            },
            initial: false,
          });
        }
        openSnackbar.call(this, 'Draft saved!');
        PublishingModule.setInitialListingState();
      })
        .catch(e => {
          openSnackbar.call(this, 'Error: could not create draft!', { type: 'error' });
          this.$log('error', e);
        })
        .finally(() => (this.loading = false));
    }
  }
  public async publishDetails() {
    if (PublishingModule.mode.edit && this.currentDraftListing.approvalRequired) {
      this.publishOpen = true;
    } else {
      await this.publish();
    }
  }

  public async publish() {
    let resp: { approvalsRequired: boolean };
    if (!PublishingModule.publishEnabled) {
      this.$log('warn', 'attempting to publish but publish should not be enabled');
      return;
    }

    this.loading = true;
    if (this.listingId) {
      const draft: DraftListingModulePayload = cloneDeep(this.currentDraftListing);
      draft.listingName = cleanOutSpecialCharAndTags(draft.listingName);
      draft.listingId = Number(this.listingId);
      if (draft.listingTypeId !== ListingTypes.API) {
        delete draft.registrationId;
        delete draft.registrationVersionId;
      }
      if (!PublishingModule.isCustomComponent) {
        (draft.extendedProperties.details as SavedDetails) = this.transformFormFields(
          draft.extendedProperties.details,
        );
      }
      if (draft.approvalRequired) {
        draft.changeReason = this.publishForm[0].value;
      }
      // cleanup legacy unsupported extended props
      const extProps: any = draft.extendedProperties;
      delete extProps.serviceName;
      extProps.documentationSections.forEach((ref:any) => {
        delete ref.sectionInfo.sectionIndex;
      });
      try {
        if (this.listingVersionId) {
          resp = (await API.publishDraftListingVersion(draft, this.listingVersionId)).data;
        } else if (this.isApi) {
          resp = (await API.publishNewDraftListingVersion(draft, this.listingId)).data;
        } else {
          resp = (await API.publishDraftListing(draft, this.listingId)).data;
        }
      } catch (e: any) {
        let msg = e?.response?.data?.message ?? 'Error: could not update listing!';
        openSnackbar.call(this, msg, { type: 'error' });
        this.$log('error', e);
        return;
      } finally {
        this.loading = false;
      }
      if (resp.approvalsRequired) {
        openSnackbar.call(this, 'Your listing is being reviewed', { type: 'information' });
        this.$router.replace({
          name: RouteNames.MyListings,
        });
      } else {
        this.$router.push({
          name: RouteNames.ListingDetails,
          params: {
            listingId: this.listingId,
          },
        });
      }
    } else {
      const draft: DraftListingModulePayload = cloneDeep(this.currentDraftListing);
      draft.listingName = cleanOutSpecialCharAndTags(draft.listingName);
      if (draft.listingTypeId !== ListingTypes.API) {
        delete draft.registrationId;
        delete draft.registrationVersionId;
      }
      if (!PublishingModule.isCustomComponent) {
        (draft.extendedProperties.details as SavedDetails) = this.transformFormFields(
          draft.extendedProperties.details,
        );
      }
      try {
        const { draftListingId, approvalsRequired } = (await API.publishNewDraftListing(draft))
          .data;
        if (approvalsRequired) {
          openSnackbar.call(this, 'Your listing is being reviewed', { type: 'information' });
          this.$router.replace({
            name: RouteNames.MyListings,
          });
        } else {
          this.$router.push({
            name: RouteNames.ListingDetails,
            params: {
              listingId: draftListingId,
            },
          });
          openSnackbar.call(this, 'Published new listing!');
        }
        PublishingModule.setInitialListingState();
      } catch (e) {
        openSnackbar.call(this, 'Error: could not publish new listing!', { type: 'error' });
        this.$log('error', e);
        return;
      } finally {
        this.loading = false;
      }
    }
  }

  get flowState() {
    return PublishingModule.mode;
  }

  public async created() {
    const edit = this.$route.query.mode === 'edit';
    const version = this.$route.query.mode === 'version';
    this.loading = true;
    await PublishingModule.getListingTypes();

    // edit version
    if (edit && this.listingVersionId) {
      // editing something that is versionable, GET w/ version endpoint
      await PublishingModule.getListing({ isVersion: true, id: this.listingVersionId });
    } else if ((edit || version) && this.listingId) {
      // handles new version because this will
      // basically base it off of the old version which is good
      await PublishingModule.getListing({ isVersion: false, id: this.listingId });
    } else if (!edit && !version) {
      // new listing, make sure self user is in the list
      await UserModule.getUserInfo();
      PublishingModule.addUsersWithPermissions(this.owners);
    } else {
      this.$logger.warn('Unexpected combination of query and path params');
    }
    PublishingModule.setMode({
      mode: {
        edit,
        version,
      },
      initial: true,
    });
    this.loading = false;
  }

  public get currentComponent(): Vue.VueConstructor | void {
    // Get current component string from store and return corresponding vue component
    const c = PublishingModule.activeTab.component || '';
    const components: { [key: string]: Vue.VueConstructor } = {
      ProductCard,
      ProductDetails,
      AddDocumentation,
    };
    return components[c];
  }

  get isAddingNewVersion(): boolean {
    return this.flowState.version;
  }

  public get getStatusList(): UITab[] {
    return PublishingModule.statusList;
  }

  public get activeTab(): UITab {
    return PublishingModule.activeTab;
  }

  public set activeTab(tab: UITab) {
    PublishingModule.updateActiveTab(tab);
  }

  public get currentDraftListing(): DraftListingModule {
    return PublishingModule.draftListing;
  }

  public get userPermissionsLink(): RawLocation {
    return {
      name: RouteNames.ListingPermissions,
    };
  }

  public get headerStyle(): Record<string, unknown> {
    return {
      backgroundColor: 'var(--theme-lightest)',
    };
  }
}
