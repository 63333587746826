
import { Component, Vue } from 'vue-property-decorator';
import { InputChips } from '@gsk-tech/gsk-input-chips/gsk-input-chips';
import GTextfield from '../gsk-components/GskTextfield.vue';
import GButton from '../gsk-components/GskButton.vue';
import ListingCard from '../catalog/CardListWithHeader/ListingCard.vue';
import { PublishingModule } from '@/store/modules/publishing.module';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import GImageUpload from '@/components/gsk-components/GskImageUpload.vue';
import { DraftListing } from '@/types/listings.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { SelectOption } from '@/components/form/form.types';
import { AppEnum, ensureAppEnum } from '@/types/enum.types';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';

@Component({
  components: {
    GTextfield,
    GSelect,
    GButton,
    GImageUpload,
    GRadioGroup,
    ListingCard,
  },
})
export default class ProductCard extends Vue {
  get isPrivate(): string {
    return Number(PublishingModule.draftListing.isPrivate).toString();
  }
  set isPrivate(p: string) {
    PublishingModule.updateListing({ isPrivate: Boolean(Number(p)) });
  }
  get privacyOptions() {
    return [
      {
        value: '1',
        label: 'Yes',
      },
      {
        value: '0',
        label: 'No',
      },
    ];
  }

  get businessUnit(): string {
    return (PublishingModule.draftListing.businessUnitId || '').toString();
  }
  set businessUnit(id: string) {
    PublishingModule.updateListingBusinessUnit(id);
  }
  get businessUnits(): SelectOption[] {
    return EnumsModule.enumsLists.businessUnit.map(bu => {
      return {
        value: bu.id.toString(),
        label: bu.name,
      };
    });
  }

  get hasCategory(): boolean {
    return this.categories.length > 0;
  }

  get category(): string {
    return (PublishingModule.draftListing.categoryId || '').toString();
  }
  set category(id: string) {
    PublishingModule.updateListingCategory(id);
  }
  get categories(): SelectOption[] {
    return PublishingModule.listingTypeCategories.map(c => {
      return {
        value: c.categoryId.toString(),
        label: c.categoryName,
      };
    });
  }

  get listingTypeEnum(): AppEnum {
    const id = this.listingTypeId;
    return ensureAppEnum(EnumsModule.enumsById.listingType[id]);
  }

  get content() {
    return PublishingModule.listingTypeTemplate.publishing.content.productCard;
  }
  get file(): string[] {
    return [PublishingModule.draftListing.extendedProperties.image].filter(i => i);
  }
  set file(f: string[]) {
    PublishingModule.setListingImage(f[0]);
  }
  get img() {
    return this.file[0] || 'no-image';
  }
  get imagePreview() {
    return this.file[0];
  }
  clicked() {
    PublishingModule.goToNextComponent();
  }
  get draftListingCard(): DraftListing {
    const d = PublishingModule.draftListing;
    return {
      ...d,
      listingId: -1,
      listingName: d.listingName || '<name>',
      listingDescription: d.listingDescription || '<description>',
      iconUrl: this.needsImage ? this.img : '',
      owners: [],
      versions: [],
      authenticationType: '',
    };
  }
  get listingName(): string {
    return PublishingModule.draftListing.listingName;
  }
  set listingName(name: string) {
    PublishingModule.updateListingName(name);
  }
  get listingDescription(): string {
    return PublishingModule.draftListing.listingDescription;
  }
  set listingDescription(words: string) {
    PublishingModule.updateListingDescription(words);
  }
  get needsImage() {
    return PublishingModule.listingTypeTemplate.publishing.info.acceptImage;
  }
  get disabled(): boolean {
    const conds = [this.listingName === '', this.listingDescription === ''];
    if (this.needsImage) {
      conds.push(this.file[0] === '');
    }
    return conds.some(b => b);
  }
  get listingTypeId(): number {
    return PublishingModule.draftListing.listingTypeId;
  }
  get publishKeywords(): string {
    return PublishingModule.draftListing.extendedProperties.keywords.join(' ');
  }
  async addKeywords(value: CustomEvent) {
    const updated = (value.target as InputChips).updateComplete;
    let i = 0;
    while (!(await updated)) {
      // wait for the updates to complete
      if (++i > 4) {
        // don't wait forever
        break;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const keywords: string[] = (value.target as any).chipSet.chips.map(
      (chip: { label: { toLowerCase: () => string } }) => chip.label.toLowerCase().trim(),
    );
    PublishingModule.updateKeywords(keywords.flatMap(v => v.split(/,/g)).filter(w => !!w));
  }
}
