
import { Component, Vue, Prop } from 'vue-property-decorator';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import { Roles, RouteNames } from '@/constants';
import { PublishingModule } from '@/store/modules/publishing.module';
import { ListingUser } from '@/types/publishing.types';
import { UserModule } from '@/store/modules/user.module';
import { openSnackbar } from '@/utils/components';
import { updateListingPermissions } from '@/api/publishing.api';
import MemberPermissions from '@/components/MemberPermissions.vue';
import TeamPermissions from '@/components/TeamPermissions.vue';
import { ProjectTeam } from '@/types/teams.types';
import { ProjectUser } from '@/types/projects.types';
import { EnumsModule } from '@/store/modules/enums.module';

@Component({
  components: {
    FullScreenForm,
    GButton,
    GPeoplePicker,
    MemberPermissions,
    TeamPermissions,
  },
})
export default class ListingPermissions extends Vue {
  @Prop(String) listingId?: string;
  private initialState: string = JSON.stringify(PublishingModule.draftListing.listingUsers);
  private owners: ListingUser[] = JSON.parse(this.initialState);
  private teams: ProjectTeam[] = JSON.parse(JSON.stringify(PublishingModule.draftListing.teams));
  private allowedRoles: { id: number; name: string }[] = [
    EnumsModule.enums.role.OWNER,
    EnumsModule.enums.role.LISTINGVIEWER,
  ];
  private initialTeams: string = JSON.stringify(this.teams);
  private modalClosed = false;

  get mappedOwners(): ListingUser[] {
    return this.owners.map(owner => {
      return {
        // roleId: Roles.Owner,
        // roleName: RoleNames.Owner,
        ...owner,
      };
    });
  }

  get currentUserIsAnOwner(): boolean {
    const { user } = UserModule;
    const currentUserIsAnOwner = this.mappedOwners.find(owner => owner.mudId === user.mudId) || this.teams.some(t => t.teamMembers.some(tm => tm.mudId === user.mudId));
    return !!currentUserIsAnOwner;
  }

  deleteMember(memberList: ProjectUser[]) {
    this.owners = memberList;
  }

  addTeam(team: ProjectTeam) {
    if (!this.teams.find(ref => ref.teamId === team.teamId)) {
      this.teams.push(team);
    }
  }

  deleteTeam(teamsList: ProjectTeam[]) {
    this.teams = teamsList;
  }

  updateTeam(updatedTeam: ProjectTeam) {
    this.teams = this.teams.map(t => t.teamId == updatedTeam.teamId ? {...updatedTeam} : t);
  }

  save() {
    this.savePermissions(this.mappedOwners).then(() => {
      if (this.currentUserIsAnOwner) {
        this.modalClosed = true;
      } else {
        // re-route to my listings page
        this.$router.replace({
          name: RouteNames.MyListings,
        });
      }
    });
  }

  async savePermissions(value: ListingUser[]) {
    let op: Promise<unknown> = Promise.resolve();
    if (this.$route.query.mode === 'edit' && this.listingId) {
      PublishingModule.addUsersWithPermissions(value);
      PublishingModule.addTeamsWithPermissions(this.teams);
      op = updateListingPermissions(value, this.listingId, this.teams);
    } else {
      PublishingModule.addUsersWithPermissions(value);
      PublishingModule.addTeamsWithPermissions(this.teams);
    }

    op.then(() => {
      openSnackbar.call(this, 'Permissions saved!');
      PublishingModule.setInitialListingState();
    }).catch(e => {
      openSnackbar.call(this, 'Error: could not save permissions!', { type: 'error' });
      this.$log('error', e);
    });
  }

  get hasOwners() {
    return !!(
      this.owners.find(user => user.roleId === Roles.Owner) ||
      this.teams.find(team => team.roleId === Roles.Owner)
    );
  }

  get canSave() {
    const hasOwners = this.hasOwners;
    return (
      hasOwners &&
      (this.initialState !== JSON.stringify(this.owners) ||
        this.initialTeams !== JSON.stringify(this.teams))
    );
  }
}
