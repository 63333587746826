
import { Component, Prop } from 'vue-property-decorator';
import truncate from 'lodash.truncate';
import { mixins } from 'vue-class-component';
import { ListingTypes, listingTypesById, RouteNames } from '@/constants';
import ListingDetailsMixin from '@/components/mixins/listing-details.mixin';
import { addAnalyticsRouteParams } from '@/analytics';
import HelpTooltip from '@/components/HelpTooltip.vue';
import PlatformTypeChip from '@/components/PlatformTypeChip.vue';
import { isEmpty } from 'lodash';

@Component({
  components: {
    HelpTooltip,
    PlatformTypeChip
  },
})
export default class ListingCard extends mixins(ListingDetailsMixin) {
  @Prop({ required: false, type: String }) readonly image?: string;

  get isComponent() {
    return (
      this.listingTypeId === ListingTypes.WebComponent ||
      this.listingTypeId === ListingTypes.MobileComponent
    );
  }

  get isPrivate() {
    return this.listing.isPrivate;
  }

  get img(): string {
    if (this.image) {
      return this.image;
    }
    if (!this.listing.hasImage) {
      return '';
    }
    return `/api/proxy/listings/${this.listing.listingId}/image`;
  }

  get iconStyle() {
    return { backgroundImage: `url("${this.icon}")` };
  }

  get aspectRatio(): string {
    return this.img ? '16-9' : '';
  }

  get description(): string {
    return truncate(this.listing.listingDescription, { length: 100 });
  }

  get listingLink() {
    const listingType = listingTypesById[this.listing.listingTypeId];
    return {
      name: RouteNames.ListingDetails,
      params: addAnalyticsRouteParams(
        { listingId: '' + this.listing.listingId },
        {
          listingName: this.listing.listingName,
          listingType,
        },
      ),
    };
  }
  get platformName() {
    return !isEmpty(this.listing.platform) ? this.listing.platform.displayName : 'Azure';
  }
}
